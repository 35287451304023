body {
  background-color: #f7f7f7;
}

.empty-div {
  width: 100%;
  padding: 3em 0 1em;
}

.empty-div-half {
  width: 100%;
  padding: 1em 0;
}

.bold-class {
  font-weight: bold;
}

.p-newpost {
  color: #a0a0a0;
}

code {
  color: #454545;
  background: #efefef;
  border: 1px solid #dfdfdf;
  min-width: 300px;
  font-size: .7em;
  line-height: 1.5em;
  display: block;
  overflow: auto;
}

.iphone-canvas {
  z-index: 999;
  position: fixed;
}

.modal {
  z-index: 2147483647;
  opacity: 0;
  pointer-events: none;
  background-color: #0009;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modal-content {
  background-color: #eee;
  border-radius: 1em;
  padding: 2em;
}

.svg-menu-plank .tert a {
  color: #db3d3d;
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.svg-menu-plank .tert a:hover {
  color: #db3d3d;
  text-decoration: underline;
}

.subenter {
  background-color: #07c;
}

.algn-btn {
  float: left;
  width: 100%;
  padding: 3em 0;
}

.err-message {
  color: #d60000;
  text-align: center;
  z-index: 9;
  position: absolute;
  bottom: 20px;
}

.custom-icon-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-icon-input:before {
  content: "Upload icon for post";
  color: #fff;
  letter-spacing: .05em;
  -webkit-user-select: none;
  cursor: pointer;
  background: #159dc3 url("icon-post.96c6bbdf.svg") 25px 17px no-repeat;
  border: none;
  border-radius: .6em;
  padding: 15px 35px 15px 58px;
  font-size: 1.1em;
  font-weight: bold;
  display: inline-block;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input:before {
  content: "Upload image";
  color: #fff;
  letter-spacing: .05em;
  -webkit-user-select: none;
  cursor: pointer;
  background: #159dc3 url("upload.b3db046e.svg") 25px 17px no-repeat;
  border: none;
  border-radius: .6em;
  padding: 15px 35px 15px 58px;
  font-size: 1.1em;
  font-weight: bold;
  display: inline-block;
}

.style-title input[type="text"] {
  text-indent: .5em;
  border-radius: .4em;
  width: 100%;
  margin: 0 0 1em;
}

.testcss {
  font-size: 6em;
}

.save-note, .save-note-in {
  z-index: 1;
  color: #fff;
  cursor: pointer;
  letter-spacing: .02em;
  background: #159dc3 url("i_load.98541c2d.gif") no-repeat;
  border: none;
  border-radius: .6em;
  align-items: center;
  width: 100%;
  font-weight: bold;
  display: block;
  position: relative;
}

.i-delete {
  background: url("i_delete.e32bb322.png") 20px no-repeat;
}

.i-delete:hover {
  background: url("i_delete_h.a94d960d.png") 20px no-repeat;
}

.p-imageurl {
  padding: 2em 0;
}

.block_about {
  width: 90%;
  position: absolute;
  left: 5%;
}

.video_about {
  background-color: #000;
  border-radius: 10px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.tag-about {
  margin: 2em 0 1em;
}

.title-h2 {
  color: #159dc3;
  margin: 1.5em 0;
  font-size: 3em;
}

.tag-about span {
  float: left;
  color: #dfdfdf;
  background-color: #5a5a5a;
  border-radius: 6px;
  margin: 0 .3em 1.5em;
  padding: .8em;
  display: block;
}

div.top-new-post {
  padding: 3.5em 2% 1.5em;
  font-size: 2.5em;
}

.top-new-post h1 {
  color: #363636;
}

.top-new-post h1, .top-new-post p {
  padding-left: 3%;
}

.top-new-post p {
  margin: 1em 2em 1em 0;
}

.txtColumn {
  color: #707070;
  padding: 4em 2em 1em;
  font-size: 1.8em;
  line-height: 1.5em;
}

.iconBlock {
  background-color: #fff;
  border-radius: 1em;
  width: 100%;
  padding: .5em 0;
  display: flex;
}

.iblock {
  vertical-align: top;
  width: 100%;
  padding: 3.5em 3em 3.5em 3%;
  display: inline-block;
}

.iconViewer {
  background-color: #e2e2e2;
  border-radius: 100%;
  min-width: 200px;
  max-width: 200px;
  height: 200px;
  margin: 2.2em;
  display: inline-block;
  overflow: hidden;
}

.iconViewer img {
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.imageViewer {
  text-align: center;
  z-index: -999;
  object-fit: cover;
  background-color: #eaeaea;
  width: 100%;
  padding: 4em 0;
  display: block;
}

.imageViewer img {
  object-fit: cover;
  max-width: 90%;
}

span.css-author {
  color: #fff;
}

.css-post {
  width: 80vw;
  margin: 0 10vw;
}

div.css-checkbox {
  background: #eaeaea;
  border-radius: .5em;
  width: 100%;
  height: 100px;
  padding-bottom: 1em;
  display: block;
}

.css-checkbox p {
  color: #8b8b8b;
  margin: -1.5em 0 0 1em;
  padding: 0 0 0 5em;
  display: block;
}

.style-title .css-checkbox input {
  float: left;
  width: 40px;
  margin: 3.5em .4em 0 2em;
  padding: 0;
  transform: scale(2);
}

.style-title .css-checkbox label {
  width: 40px;
  padding-left: 1em;
  line-height: 3.6em;
  display: inline;
}

label.style-select {
  padding: 0 0 1.5em;
}

.style-select span {
  padding: 0 2em 0 0;
  display: block;
}

.style-select select {
  text-indent: .5em;
  border-radius: .4em;
  width: 100%;
  padding: .5em 0;
  font-size: 1.1em;
}

input.input-file {
  text-indent: 0;
  padding: 1em 0;
  font-size: 1.2em;
}

.style-simplemde {
  font-size: 2em;
}

span.empty-block {
  padding: 5em 0;
  display: block;
}

.css-txt {
  display: block;
}

.css-txt p {
  color: #595959;
  padding: .5em 0;
}

.h2-position {
  z-index: 2147483647;
  color: #fff;
  font-size: 1.5em;
  position: absolute;
  bottom: 10vh;
  left: 4vw;
}

div.absPostPart {
  padding: 0 0 2em;
}

.svg-plank {
  cursor: pointer;
  float: right;
  height: 25px;
  margin-top: -4px;
  padding: 0 .1em 2em;
  font: 1em Arial, sans-serif;
  display: inline-block;
}

.svg-plank svg {
  padding: 1em;
}

.svg-plank:hover {
  background: #676767;
}

.svg-menu-plank {
  z-index: -1;
}

.svg-menu-plank a {
  color: #035367;
  padding: 1em 20px 1em 38px;
}

.svg-menu-plank a:hover {
  color: #a80000;
}

.dxnv {
  opacity: 1;
  padding-top: 50px;
  transition: height .3s;
}

.dddd {
  opacity: 0;
  height: 0;
  margin: 0;
  padding: 0;
  transition: height .3s;
  overflow: hidden;
}

.svg-menu-plank h4 {
  padding: .6em 1em 1em 1.1em;
  font-weight: bold;
}

.svg-menu-plank span {
  margin-left: 10px;
}

.svg-menu-plank li > .css-delete {
  background: url("delete.4dddbcbf.svg") 8px 12px no-repeat;
  padding-left: 34px;
}

a.delete-comm {
  color: #bebebe;
  margin-top: 2em;
  margin-bottom: -2em;
  padding: .5em 0;
  font-size: .8em;
  display: block;
}

a.delete-comm:hover {
  color: #4d4d4d;
}

.wdth {
  float: left;
}

.svg-menu-plank li > .css-edit {
  background: url("edit.cabf96a0.svg") 8px 13px no-repeat;
  padding-left: 34px;
}

.css-plank {
  display: inline-flexbox;
  background-color: #c2f3ff;
  border-radius: 6px;
  max-width: 700px;
  margin: 2em 0;
  overflow: hidden;
}

.css-plank li {
  float: left;
}

.plank-span {
  padding: 1.1em;
}

.css-plank .fp {
  opacity: .6;
}

.css-plank li > a {
  color: #035367;
  background: none;
  padding: 1.1em;
  text-decoration: none;
  display: inline-block;
}

.css-plank li > a:hover {
  color: #fff;
  background-color: #1798bb;
}

.fPost {
  width: 100%;
  height: 100%;
  position: absolute;
}

.txtfPOst {
  z-index: 9;
  margin-left: 10%;
  padding-top: 20vh;
  position: absolute;
}

.txtfPOst h1 {
  opacity: .8;
  color: #fff;
}

.rt-lenta-post {
  float: right;
  position: absolute;
}

.rt-lenta-post a {
  color: #fff;
  padding: 1em 0;
  font-size: 1.3em;
  line-height: 1.5em;
  display: block;
}

.rt-lenta-post a:hover {
  color: #e1c2af;
}

.wrapper {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: #45d6ff1a;
  border-radius: 1em;
  max-width: 500px;
  margin: 0 auto;
}

.ErrorBlock {
  color: #0009;
  width: 40%;
  height: 100px;
  margin: 15% 10% 0 5%;
  font-size: 4em;
  font-weight: bold;
  line-height: 1.4em;
  position: absolute;
}

.loading-signinup {
  width: 50%;
  margin-top: 20vh;
  margin-left: 25%;
}

.loading-signinup p {
  text-align: center;
  font-size: 2em;
}

.css-userform {
  padding-top: 15vh;
  position: absolute;
}

.css-userform h2 {
  text-align: left;
  color: #fff;
}

.css-userform button {
  width: 100%;
}

.myposts-li-block {
  padding-top: 3em;
}

.myposts-li-block li {
  border-bottom: 1px solid #c4c4c4;
  font-weight: bold;
  display: block;
}

.myposts-li-block a h1 {
  color: #0286ab;
  padding: .2em 0;
}

.myposts-li-block a {
  color: #0286ab;
}

.myposts-li-block a:hover h1 {
  color: #363636;
}

.clickMore {
  text-align: center;
  cursor: pointer;
  background-color: #e6e6e6;
  padding: 2em 0;
  font-size: 1.2em;
  font-weight: bold;
}

.top_commbocks {
  width: 100%;
  max-width: calc(800px + 2em);
  display: inline-block;
}

.top_commbocks h3 {
  color: #979797;
  float: left;
  font-weight: normal;
}

.comments-block {
  width: 80%;
  margin: -4em 10% 5%;
  display: inline-block;
}

.comments-block form {
  padding-bottom: 4em;
}

.comments-block textarea {
  border: none;
  border-radius: 6px;
  width: calc(100% - 1em);
  max-width: 800px;
  height: 100px;
  margin: 0 0 2em;
  padding: .5em;
  font-size: 1.4em;
  display: block;
}

.comments-block button {
  color: #fff;
  float: left;
  background-color: #07c;
  border: none;
  border-radius: 1em;
  padding: .5em 1.5em;
  font-size: 1em;
}

.length-comments {
  float: right;
  color: #b9b9b9;
  margin-bottom: 1.5em;
}

.comment-block {
  border-bottom: 1px solid #bebebe;
  padding: 1.6em 0;
}

.comments-block h3 {
  float: left;
  padding-right: 1em;
  font-size: 1.2em;
  display: inline;
}

.comment-block span {
  color: #8d8d8d;
}

.comment-block p {
  color: #474747;
  padding-top: .6em;
  font-size: 1.2em;
}

.form-comment {
  max-width: 800px;
  margin-top: 5em;
}

.author-comment {
  padding-right: 1em;
  font-size: 1.1em;
  font-weight: bold;
}

.futer-block {
  padding: 5em 3em;
}

.futer-block h3 {
  margin: 5 em 2em;
}

.cats_block {
  padding-top: 3em;
}

.cats_block li {
  border-bottom: 1px solid #c4c4c4;
  font-weight: bold;
  display: block;
}

.cats_block li h1 {
  padding: .2em 0;
}

.cats_block li h1:hover {
  color: #363636;
}

.li-post-flex {
  margin-top: .3em;
  display: flex;
}

.iconPost img.no-avatar {
  float: left;
  object-fit: none;
  position: absolute;
}

.iconPost {
  float: left;
  background-color: #e2e2e2;
  border-radius: 100%;
  margin: 0 2.2em 2.2em 0;
  display: block;
  position: absolute;
  overflow: hidden;
}

.iconPost img {
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.css-plank-cat {
  float: left;
  background-color: #c2f3ff;
  border-radius: 8px;
  margin-top: 1em;
  padding: 1em 0;
  font-weight: normal;
  display: block;
  overflow: hidden;
}

.css-plank-cat span {
  padding: 2em .7em;
}

.css-plank-cat a {
  padding: 1em .7em;
}

.css-plank-cat a:hover {
  color: #fff;
  background-color: #1798bb;
}

.cats_block a {
  color: #0286ab;
}

.cats_block a:hover li {
  color: #47bc00;
}

.top-block {
  padding: 3em 4% 0 0;
}

.top-block h2 {
  color: #363636;
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-family: Arial, sans-serif;
}

.all-post-block {
  width: 100%;
  padding-top: 1em;
  display: block;
}

.all-post-block:before, .all-post-block:after {
  content: "";
  display: table;
}

.all-post-block:after {
  clear: both;
}

.all-post-block {
  clear: both;
  zoom: 1;
}

.all_post {
  float: left;
  color: #fff;
  background-color: #00b5e3;
  border-radius: 10px;
  padding: 1.2em 2em;
}

span.uname_weight {
  font-weight: bold;
}

div.xclose:hover {
  opacity: 1;
  transform: rotate(360deg);
}

.xcls-1 {
  z-index: 0;
}

.xcls-1, .xcls-2 {
  fill-rule: evenodd;
}

.xcls-2 {
  fill: #00b5e3;
  z-index: 1;
  transform-origin: 50%;
  transition: all .3s;
}

.xcls-1:hover + .xcls-2 {
  opacity: 1;
  transform: rotate(360deg);
}

.small-menu-01 {
  fill: none;
  stroke: #8ed9ed;
  stroke-width: 2px;
}

.small-menu-02 {
  fill: #8ed9ed;
  fill-rule: evenodd;
}

.css-back {
  z-index: 999;
  opacity: .8;
  width: 50px;
  height: 80px;
  position: fixed;
  top: 15%;
  right: 7%;
}

.css-back:hover:after {
  content: "back";
  z-index: 1;
  background: #ffffe6e6;
  border-radius: 3px;
  padding: 5px 10px;
  font-family: Arial, sans-serif;
  font-size: 11px;
}

.loader-canvas {
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
}

.bg-canvas {
  z-index: -1;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
}

.bg-canvas img {
  height: 100vh;
}

.black_bg {
  z-index: -2;
  background-color: #000;
  width: calc(100vw - 22px);
  height: 100vh;
  position: absolute;
}

.htcanvas {
  z-index: -99999;
  border-radius: 1em;
  width: 100%;
  height: 100%;
  margin-bottom: 4em;
}

.post-canvas {
  float: left;
  align-content: center;
  width: 100%;
  position: relative;
}

.post-canvas canvas {
  height: 60vh;
  position: relative;
}

.img-post img {
  border-radius: 8px;
  max-width: 100%;
}

.imgStyle {
  float: left;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.imgStyle img {
  object-fit: cover;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

input.script-url {
  float: left;
  position: relative;
  transform: scale(2);
}

.script-url label {
  margin-left: 8em;
  position: relative;
  transform: scale(2);
}

@media screen and (min-width: 376px) and (max-width: 576px) {
  code {
    width: calc(100% - 2em);
    margin: 2.5em 0;
    padding: 1em;
    display: block;
  }

  .css-plank {
    font-size: 1em;
  }

  .all-post-block {
    padding-top: 0;
    font-size: .8em;
  }

  .length-comments {
    float: left;
    padding-top: .5em;
    font-size: .8em;
  }

  .img-post img, .comments-block textarea {
    border-radius: 3px;
  }

  .comments-block button {
    border-radius: 5px;
    font-size: .8em;
  }

  .comments-block {
    margin-top: -6em;
  }

  .svg-menu-plank a {
    padding-top: 1.4em;
  }

  .comment-block {
    font-size: .8em;
  }

  a.delete-comm {
    margin-top: 1em;
  }

  .txtfPOst h1 {
    width: 90%;
    margin-top: 50vw;
    font-size: 3em;
  }

  .rt-lenta-post {
    display: none;
  }

  .iphone-canvas {
    width: 90vw;
    height: 60vh;
    left: 5%;
  }

  .myposts-li-block a h1 {
    font-size: 2.3em;
  }

  .post-cont {
    margin-left: 90px;
  }

  .iconPost {
    min-width: 70px;
    max-width: 70px;
    height: 70px;
    margin-top: .7em;
  }

  .css-plank-cat {
    margin-top: .4em;
    font-size: .8em;
  }

  .myposts-li-block li {
    padding: 1em 5% 5.5em;
  }

  .all_post {
    border-radius: 6px;
    margin: 4em 0 0 5%;
    padding: 1em;
    font-size: .8em;
  }

  .svg-plank svg {
    height: 25px;
  }

  .li-svg-plank {
    float: right;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .svg-menu-plank {
    float: left;
    width: 100%;
    margin-top: -43px;
    left: 0;
  }

  .top-block h2 {
    padding: 1.5em 0 0;
    font-size: 2.2em;
    font-weight: bold;
    line-height: 1.1em;
  }

  .css-txt {
    padding: 0 0 2em;
    font-size: 1em;
    line-height: 1.3em;
    display: block;
  }

  .css-txt a {
    background: #45abc5;
    padding: 1px;
  }

  .img-post {
    padding: 1em 0;
  }

  .imgStyle {
    max-height: 450px;
    margin: 1em 0 2em;
  }

  .cats_block li h1 {
    padding: .2em 0;
    font-size: 1.5em;
  }

  .cats_block li {
    padding: 1.2em 5% 5em;
  }

  .top-new-post h1 {
    font-size: 1.8em;
  }

  .p-newpost {
    font-size: .7em;
  }

  .wrapper {
    padding: 2.5em;
  }

  .css-userform {
    width: 80vw;
    margin: 0 10%;
    padding-top: 18vh;
  }

  .css-userform h2 {
    font-size: 2.4em;
  }

  label {
    margin-top: 1.3em;
    font-size: 1.2em;
  }

  input[type="text"], #password {
    font-size: 1.1em;
  }

  .css-userform button {
    width: 100%;
  }

  .save-note, .save-note-in {
    background-size: 1.4em;
    padding: 19px 10px 19px 35px;
    font-size: 1em;
  }

  .save-note {
    background-position: calc(50% - 65px) calc(50% - 2px);
  }

  .save-note-in {
    background-position: calc(50% - 50px) calc(50% - 2px);
  }

  .err-message {
    width: 90%;
    bottom: -6em;
    left: 5%;
  }

  .up {
    bottom: -4em;
  }

  .iconPost img.no-avatar {
    object-fit: fill;
    width: 50%;
    height: 50%;
    margin: 25%;
  }

  .futer-block {
    font-size: .8em;
  }
}

@media screen and (min-width: 576px) and (max-width: 960px) {
  code {
    float: left;
    width: calc(100% - 2em);
    margin: 2em 0;
    padding: 1em;
  }

  .comments-block {
    margin-top: .2em;
  }

  .txtfPOst h1 {
    width: 50vw;
    font-size: 4em;
    line-height: 1.1em;
  }

  .rt-lenta-post {
    float: left;
    width: 95vw;
    display: flex;
    top: 82vh;
    left: 5vw;
  }

  .rt-lenta-post div {
    float: left;
    width: 30%;
    margin-right: 3%;
    display: block;
  }

  .iphone-canvas {
    width: 55vw;
    height: 100vh;
    top: -70px;
    right: 2vw;
  }

  .myposts-li-block a h1 {
    font-size: 3em;
  }

  .post-cont {
    margin-left: 150px;
  }

  .iconPost {
    min-width: 120px;
    max-width: 120px;
    height: 120px;
    margin-top: 1em;
  }

  .css-plank-cat {
    font-size: .9em;
  }

  .myposts-li-block li {
    padding: 1em 5% 5.5em;
  }

  .all_post {
    margin: 4em 0 0 5%;
  }

  .all-post-block {
    font-size: .9em;
  }

  .imgStyle {
    max-height: 450px;
    margin: 1em 0 2em;
  }

  .top-block h2 {
    padding: 1em 0 0;
    font-size: 4em;
    font-weight: bold;
    line-height: 1em;
  }

  .css-txt {
    padding: 0 0 2em;
    font-size: 1em;
    line-height: 1.6em;
    display: block;
  }

  .svg-menu-plank {
    float: left;
    width: 100%;
  }

  .dxnv {
    padding-top: 0;
  }

  .img-post {
    padding: 1em 0;
  }

  .cats_block li h1 {
    padding: .2em 0;
    font-size: 3em;
  }

  .cats_block li {
    padding: 1.2em 5% 6em;
  }

  .cats_block ul {
    margin-top: 1em;
  }

  .top-new-post h1 {
    font-size: 2.5em;
  }

  .p-newpost {
    font-size: 1em;
  }

  .wrapper {
    padding: 3em;
  }

  .css-userform {
    width: 60vw;
    margin: 0 20%;
    padding-top: 20vh;
  }

  .css-userform h2 {
    font-size: 2.7em;
  }

  label {
    margin-top: 1.5em;
    font-size: 1.2em;
  }

  input[type="text"], #password {
    font-size: 1.2em;
  }

  .css-userform button {
    width: 100%;
  }

  .save-note, .save-note-in {
    background-size: 1.4em;
    padding: 19px 10px 19px 35px;
    font-size: 1em;
  }

  .save-note {
    background-position: calc(50% - 65px) calc(50% - 2px);
  }

  .save-note-in {
    background-position: calc(50% - 50px) calc(50% - 2px);
  }

  .err-message {
    width: 90%;
    bottom: -6em;
    left: 5%;
  }

  .up {
    bottom: -4em;
  }
}

@media screen and (max-width: 376px) {
  .css-post {
    width: 90%;
    margin: 0 5%;
  }

  code {
    width: calc(100% - 2em);
    min-width: auto;
    margin: 1em 0;
    padding: 1em;
    font-size: .6em;
  }

  .comments-block {
    margin-top: -7em;
    display: inline-block;
  }

  .length-comments {
    float: left;
    padding-top: 1em;
  }

  .txtfPOst h1 {
    width: 90%;
    margin-top: 75vw;
    font-size: 2.5em;
    line-height: 1.2em;
  }

  .iphone-canvas {
    width: 90%;
    height: 50vh;
    top: 15vw;
    left: 5vw;
  }

  .txtfPOst {
    width: 90%;
  }

  .rt-lenta-post {
    display: none;
  }

  .iconBlock {
    padding: 0 1em;
    display: block;
  }

  .iblock button {
    float: none;
    margin: 2em 0;
  }

  .p-imageurl {
    padding: 0 1em 0 0;
  }

  .iblock {
    padding-top: 1.5em;
    display: block;
  }

  .myposts-li-block a h1 {
    font-size: 1.4em;
  }

  .post-cont {
    margin-left: 90px;
  }

  .iconPost {
    min-width: 70px;
    max-width: 70px;
    height: 70px;
    margin-top: .3em;
  }

  .css-plank-cat {
    font-size: .7em;
  }

  .myposts-li-block li {
    padding: 1em 5% 4.5em;
  }

  .all_post {
    margin: 3em 0 0 5%;
    padding: 1em 1.8em;
    font-size: .9em;
  }

  .css-plank-cat span {
    padding: 2em 1em 2em 0;
  }

  .top-block h2 {
    padding: 1.6em 0 0;
    font-size: 2em;
    font-weight: bold;
    line-height: 1.2em;
  }

  .css-txt p {
    font-size: .5em;
    line-height: 1.4em;
  }

  .css-txt {
    padding: 0 0 2em;
    font-size: 2em;
    line-height: 1.3em;
    display: block;
  }

  .img-post {
    padding: 1em 0;
  }

  .imgStyle {
    max-height: 500px;
    margin: 1em 0 2em;
  }

  .css-plank {
    font-size: .9em;
  }

  .dxnv {
    padding-top: 0;
  }

  .svg-menu-plank {
    float: left;
  }

  .cats_block li h1 {
    padding: .2em 0;
    font-size: 1.4em;
  }

  .cats_block li {
    padding: 1.2em 5% 4.3em;
  }

  .top-new-post h1 {
    font-size: 1.5em;
  }

  .p-newpost {
    font-size: .6em;
  }

  .wrapper {
    padding: 2em;
  }

  .css-userform {
    width: 90vw;
    margin: 0 5%;
    padding-top: 15vh;
  }

  .css-userform h2 {
    font-size: 1.5em;
  }

  label {
    margin-top: 1em;
    font-size: 1.2em;
  }

  input[type="text"], #password {
    font-size: 1em;
  }

  .css-userform button {
    width: 100%;
  }

  .save-note, .save-note-in {
    background-size: 1.4em;
    padding: 16px 10px 16px 35px;
    font-size: .9em;
  }

  .save-note {
    background-position: calc(50% - 65px) calc(50% - 2px);
  }

  .save-note-in {
    background-position: calc(50% - 50px) calc(50% - 2px);
  }

  .err-message {
    width: 90%;
    bottom: -6em;
    left: 5%;
  }

  .up {
    bottom: -4em;
  }

  .iconPost img.no-avatar {
    object-fit: fill;
    width: 50%;
    height: 50%;
    margin: 25%;
  }
}

@media screen and (min-width: 960px) and (max-width: 1200px) {
  code {
    float: left;
    width: calc(100% - 2em);
    margin: 3em 0;
    padding: 2em;
    font-size: .9em;
  }

  .comments-block {
    margin-top: 0;
  }

  .txtfPOst h1 {
    width: 45vw;
    font-size: 5em;
  }

  .rt-lenta-post {
    width: 20vw;
    display: block;
    top: 20vh;
    right: 2vw;
  }

  .iphone-canvas {
    width: 55vw;
    height: calc(100vh - 32px);
    top: 32px;
    left: 28vw;
  }

  .myposts-li-block a h1 {
    font-size: 3em;
  }

  .css-plank-cat {
    font-size: 1em;
  }

  .post-cont {
    margin-left: 160px;
  }

  .iconPost {
    min-width: 130px;
    max-width: 130px;
    height: 130px;
    margin-top: 1em;
  }

  .myposts-li-block li {
    padding: 1em 5% 7em;
  }

  .all_post {
    margin: 4em 0 0 5%;
  }

  .top-block h2 {
    padding: .8em 0 0;
    font-size: 6em;
    line-height: 1em;
  }

  .css-txt {
    margin-top: 2em;
    font-size: 1.4em;
    line-height: 1.6em;
    display: block;
  }

  .img-post {
    padding: 2em 0;
  }

  .imgStyle {
    max-height: 500px;
    margin: 2em 0 5em;
  }

  .cats_block li h1 {
    padding: .2em 0;
    font-size: 4em;
  }

  .cats_block li {
    padding: 1.5em 5% 6.5em;
  }

  .top-new-post h1 {
    font-size: 2.5em;
  }

  .p-newpost {
    font-size: 1.1em;
  }

  .wrapper {
    padding: 3em 5em;
  }

  .css-userform {
    width: 50vw;
    margin: 0 25%;
    padding-top: 18vh;
  }

  .css-userform h2 {
    font-size: 2.8em;
  }

  label {
    margin-top: 1.5em;
    font-size: 1.2em;
  }

  input[type="text"], #password {
    font-size: 1.2em;
  }

  .css-userform button {
    width: 100%;
  }

  .save-note, .save-note-in {
    background-size: 1.4em;
    padding: 19px 10px 19px 35px;
    font-size: 1em;
  }

  .save-note {
    background-position: calc(50% - 65px) calc(50% - 2px);
  }

  .save-note-in {
    background-position: calc(50% - 50px) calc(50% - 2px);
  }

  .err-message {
    width: 90%;
    bottom: -6em;
    left: 5%;
  }

  .up {
    bottom: -4em;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  code {
    float: left;
    width: calc(100% - 4em);
    margin: 2.5em 0;
    padding: 2em;
  }

  .comments-block {
    margin-top: 0;
  }

  .txtfPOst h1 {
    font-size: 6em;
  }

  .rt-lenta-post {
    width: 20vw;
    display: block;
    top: 20vh;
    right: 2vw;
  }

  .iphone-canvas {
    width: 35vw;
    height: calc(100vh - 72px);
    top: 72px;
    left: 40vw;
  }

  .txtfPOst {
    width: 50vw;
  }

  .myposts-li-block a h1 {
    font-size: 4em;
  }

  .post-cont {
    margin-left: 200px;
  }

  .iconPost {
    min-width: 160px;
    max-width: 160px;
    height: 160px;
    margin-top: 1em;
  }

  .css-plank-cat {
    font-size: 1em;
  }

  .myposts-li-block li {
    padding: 2em 5% 8em;
  }

  .all_post {
    margin: 5em 0 3em 5%;
  }

  .top-block h2 {
    padding: .8em 0 0;
    font-size: 7em;
    line-height: .9em;
  }

  .css-txt {
    padding: 0 0 2em;
    font-size: 1.8em;
    line-height: 1.3em;
    display: block;
  }

  .img-post {
    padding: 2em 0;
  }

  .imgStyle {
    max-height: 450px;
    margin: 2em 0 5em;
  }

  .cats_block li h1 {
    padding: .2em 0;
    font-size: 4em;
  }

  .cats_block li {
    padding: 2.5em 5% 7em;
  }

  .top-new-post h1 {
    font-size: 2.5em;
  }

  .p-newpost {
    font-size: 1em;
  }

  .wrapper {
    padding: 3em 7em 3em 6em;
  }

  .css-userform {
    width: 50vw;
    margin: 0 25%;
    padding-top: 18vh;
  }

  .css-userform h2 {
    font-size: 3.5em;
  }

  label {
    margin-top: 1.5em;
    font-size: 1.4em;
  }

  input[type="text"], #password {
    font-size: 1.4em;
  }

  .css-userform button {
    width: 100%;
  }

  .save-note, .save-note-in {
    background-size: 1.2em;
    padding: 20px 10px 20px 35px;
    font-size: 1.1em;
  }

  .save-note {
    background-position: calc(50% - 75px) calc(50% - 2px);
  }

  .save-note-in {
    background-position: calc(50% - 60px) calc(50% - 2px);
  }

  .err-message {
    width: 90%;
    bottom: -5em;
    left: 5%;
  }

  .up {
    bottom: -4em;
  }
}

@media screen and (min-width: 1336px) {
  code {
    float: left;
    width: calc(100% - 2em);
    margin: 3em 0;
    padding: 2em;
  }

  .comments-block {
    margin-top: 1em;
  }

  .txtColumn {
    column-count: 2;
    column-width: 40%;
    column-gap: 7%;
  }
}

@media screen and (max-width: 1336px) {
  .txtColumn {
    column-count: none;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1920px) {
  code {
    width: calc(100% - 4em);
    margin: 3em 0;
    padding: 2em;
    display: inline-block;
  }

  .txtfPOst h1 {
    font-size: 7em;
  }

  .rt-lenta-post {
    width: 20vw;
    display: block;
    top: 20vh;
    right: 2vw;
  }

  .iphone-canvas {
    width: 35vw;
    height: calc(100vh - 72px);
    top: 72px;
    left: 40vw;
  }

  .txtfPOst {
    width: 50vw;
  }

  .myposts-li-block a h1 {
    font-size: 4em;
  }

  .post-cont {
    margin-left: 230px;
  }

  .iconPost {
    min-width: 180px;
    max-width: 180px;
    height: 180px;
    margin-top: 1em;
  }

  .css-plank-cat {
    font-size: 1em;
  }

  .all_post {
    margin: 5em 0 3em 5%;
  }

  .myposts-li-block li {
    padding: 2em 5% 8em;
  }

  .top-block h2 {
    padding: .8em 0 0;
    font-size: 7em;
    line-height: 1em;
  }

  .css-txt {
    font-size: 2em;
    line-height: 1.3em;
    display: block;
  }

  .img-post {
    padding: 2em 0;
  }

  .imgStyle {
    max-height: 500px;
    margin: 2em 0 5em;
  }

  .cats_block li h1 {
    padding: .3em 0;
    font-size: 4em;
  }

  .cats_block li {
    padding: 2em 5% 7.5em;
  }

  .top-new-post h1 {
    font-size: 2.5em;
  }

  .p-newpost {
    font-size: 1em;
  }

  .wrapper {
    padding: 3em 7em 3em 6em;
  }

  .css-userform {
    width: 50vw;
    margin: 0 25%;
    padding-top: 18vh;
  }

  .css-userform h2 {
    font-size: 3.5em;
  }

  label {
    margin-top: 1.5em;
    font-size: 1.4em;
  }

  input[type="text"], #password {
    font-size: 1.4em;
  }

  .css-userform button {
    width: 100%;
  }

  .save-note, .save-note-in {
    background-size: 1.2em;
    padding: 20px 10px 20px 35px;
    font-size: 1.1em;
  }

  .save-note {
    background-position: calc(50% - 75px) calc(50% - 2px);
  }

  .save-note-in {
    background-position: calc(50% - 60px) calc(50% - 2px);
  }

  .err-message {
    width: 90%;
    bottom: -5em;
    left: 5%;
  }

  .up {
    bottom: -4em;
  }
}

@media screen and (min-width: 1920px) {
  code {
    width: calc(90% - 4em);
    max-width: 800px;
    margin: 3em 100% 3em 0;
    padding: 2em;
    display: inline-block;
  }

  .txtfPOst h1 {
    font-size: 7em;
  }

  .rt-lenta-post {
    width: 20vw;
    display: block;
    top: 20vh;
    right: 2vw;
  }

  .iphone-canvas {
    width: 35vw;
    height: calc(100vh - 72px);
    top: 72px;
    left: 40vw;
  }

  .txtfPOst {
    width: 50vw;
  }

  .myposts-li-block a h1 {
    font-size: 4em;
  }

  .post-cont {
    margin-left: 230px;
  }

  .iconPost {
    min-width: 180px;
    max-width: 180px;
    height: 180px;
    margin-top: 1.5em;
  }

  .css-plank-cat {
    font-size: 1em;
  }

  .all_post {
    margin: 5em 0 3em 5%;
  }

  .myposts-li-block li {
    padding: 2.5em 5% 8.5em;
  }

  .top-block h2 {
    padding: .8em 0 0;
    font-size: 7.5em;
    line-height: 1.2em;
  }

  .css-txt {
    font-size: 2em;
    line-height: 1.3em;
    display: block;
  }

  .css-txt p {
    width: 100%;
    display: block;
  }

  .img-post {
    padding: 2em 0;
  }

  .imgStyle {
    max-height: 800px;
    margin: 2em 0 5em;
  }

  .cats_block li h1 {
    padding: .4em 0;
    font-size: 4em;
  }

  .cats_block li {
    padding: 2.5em 5% 8em;
  }

  .top-new-post h1 {
    font-size: 2.5em;
  }

  .p-newpost {
    font-size: 1em;
  }

  .css-userform button {
    width: 100%;
    margin-top: 1.3em;
  }

  .wrapper {
    padding: 3em 7em 3em 6em;
  }

  .css-userform {
    width: 50vw;
    margin: 0 25%;
    padding-top: 18vh;
  }

  .css-userform h2 {
    font-size: 3.5em;
  }

  label {
    margin-top: 1.5em;
    font-size: 1.4em;
  }

  input[type="text"], #password {
    font-size: 1.4em;
  }

  .css-userform button {
    width: 100%;
  }

  .save-note, .save-note-in {
    background-size: 1.2em;
    padding: 20px 10px 20px 35px;
    font-size: 1.1em;
    top: -1em;
  }

  .save-note {
    background-position: calc(50% - 75px) calc(50% - 2px);
  }

  .save-note-in {
    background-position: calc(50% - 60px) calc(50% - 2px);
  }

  .err-message {
    width: 90%;
    bottom: -5em;
    left: 5%;
  }

  .up {
    bottom: -4em;
  }
}

.CodeMirror {
  color: #000;
  direction: ltr;
  height: 300px;
  font-family: monospace;
}

.CodeMirror-lines {
  padding: 4px 0;
}

.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px;
}

.CodeMirror-gutter-filler, .CodeMirror-scrollbar-filler {
  background-color: #fff;
}

.CodeMirror-gutters {
  white-space: nowrap;
  background-color: #f7f7f7;
  border-right: 1px solid #ddd;
}

.CodeMirror-linenumber {
  text-align: right;
  color: #999;
  white-space: nowrap;
  min-width: 20px;
  padding: 0 3px 0 5px;
}

.CodeMirror-guttermarker {
  color: #000;
}

.CodeMirror-guttermarker-subtle {
  color: #999;
}

.CodeMirror-cursor {
  border-left: 1px solid #000;
  border-right: none;
  width: 0;
}

.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}

.cm-fat-cursor .CodeMirror-cursor {
  background: #7e7;
  width: auto;
  border: 0 !important;
}

.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}

.cm-fat-cursor .CodeMirror-line::selection {
  background: none;
}

.cm-fat-cursor .CodeMirror-line > span::selection {
  background: none;
}

.cm-fat-cursor .CodeMirror-line > span > span::selection {
  background: none;
}

.cm-fat-cursor .CodeMirror-line::-moz-selection {
  background: none;
}

.cm-fat-cursor .CodeMirror-line > span::-moz-selection {
  background: none;
}

.cm-fat-cursor .CodeMirror-line > span > span::-moz-selection {
  background: none;
}

.cm-fat-cursor {
  caret-color: #0000;
}

@keyframes blink {
  50% {
    background-color: #0000;
  }
}

.cm-tab {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  display: inline-block;
}

.CodeMirror-rulers {
  position: absolute;
  inset: -50px 0 0;
  overflow: hidden;
}

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  position: absolute;
  top: 0;
  bottom: 0;
}

.cm-s-default .cm-header {
  color: #00f;
}

.cm-s-default .cm-quote {
  color: #090;
}

.cm-negative {
  color: #d44;
}

.cm-positive {
  color: #292;
}

.cm-header, .cm-strong {
  font-weight: 700;
}

.cm-em {
  font-style: italic;
}

.cm-link {
  text-decoration: underline;
}

.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-keyword {
  color: #708;
}

.cm-s-default .cm-atom {
  color: #219;
}

.cm-s-default .cm-number {
  color: #164;
}

.cm-s-default .cm-def {
  color: #00f;
}

.cm-s-default .cm-variable-2 {
  color: #05a;
}

.cm-s-default .cm-type, .cm-s-default .cm-variable-3 {
  color: #085;
}

.cm-s-default .cm-comment {
  color: #a50;
}

.cm-s-default .cm-string {
  color: #a11;
}

.cm-s-default .cm-string-2 {
  color: #f50;
}

.cm-s-default .cm-meta, .cm-s-default .cm-qualifier {
  color: #555;
}

.cm-s-default .cm-builtin {
  color: #30a;
}

.cm-s-default .cm-bracket {
  color: #997;
}

.cm-s-default .cm-tag {
  color: #170;
}

.cm-s-default .cm-attribute {
  color: #00c;
}

.cm-s-default .cm-hr {
  color: #999;
}

.cm-s-default .cm-link {
  color: #00c;
}

.cm-s-default .cm-error, .cm-invalidchar {
  color: red;
}

.CodeMirror-composing {
  border-bottom: 2px solid;
}

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}

.CodeMirror-matchingtag {
  background: #ff96004d;
}

.CodeMirror-activeline-background {
  background: #e8f2ff;
}

.CodeMirror {
  background: #fff;
  position: relative;
  overflow: hidden;
}

.CodeMirror-scroll {
  z-index: 0;
  outline: 0;
  height: 100%;
  margin-bottom: -50px;
  margin-right: -50px;
  padding-bottom: 50px;
  position: relative;
  overflow: scroll !important;
}

.CodeMirror-sizer {
  border-right: 50px solid #0000;
  position: relative;
}

.CodeMirror-gutter-filler, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-vscrollbar {
  z-index: 6;
  outline: 0;
  display: none;
  position: absolute;
}

.CodeMirror-vscrollbar {
  top: 0;
  right: 0;
  overflow: hidden scroll;
}

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow: scroll hidden;
}

.CodeMirror-scrollbar-filler {
  bottom: 0;
  right: 0;
}

.CodeMirror-gutter-filler {
  bottom: 0;
  left: 0;
}

.CodeMirror-gutters {
  z-index: 3;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.CodeMirror-gutter {
  white-space: normal;
  vertical-align: top;
  height: 100%;
  margin-bottom: -50px;
  display: inline-block;
}

.CodeMirror-gutter-wrapper {
  z-index: 4;
  position: absolute;
  background: none !important;
  border: none !important;
}

.CodeMirror-gutter-background {
  z-index: 4;
  position: absolute;
  top: 0;
  bottom: 0;
}

.CodeMirror-gutter-elt {
  cursor: default;
  z-index: 4;
  position: absolute;
}

.CodeMirror-gutter-wrapper ::selection {
  background-color: #0000;
}

.CodeMirror-gutter-wrapper ::selection {
  background-color: #0000;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px;
}

.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  font-family: inherit;
  font-size: inherit;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
  background: none;
  border-width: 0;
  border-radius: 0;
  margin: 0;
  position: relative;
  overflow: visible;
}

.CodeMirror-wrap pre.CodeMirror-line, .CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  z-index: 0;
  position: absolute;
  inset: 0;
}

.CodeMirror-linewidget {
  z-index: 2;
  padding: .1px;
  position: relative;
}

.CodeMirror-code {
  outline: 0;
}

.CodeMirror-gutter, .CodeMirror-gutters, .CodeMirror-linenumber, .CodeMirror-scroll, .CodeMirror-sizer {
  box-sizing: content-box;
}

.CodeMirror-measure {
  visibility: hidden;
  width: 100%;
  height: 0;
  position: absolute;
  overflow: hidden;
}

.CodeMirror-cursor {
  pointer-events: none;
  position: absolute;
}

.CodeMirror-measure pre {
  position: static;
}

div.CodeMirror-cursors {
  visibility: hidden;
  z-index: 3;
  position: relative;
}

div.CodeMirror-dragcursors, .CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}

.CodeMirror-crosshair {
  cursor: crosshair;
}

.CodeMirror-line::selection {
  background: #d7d4f0;
}

.CodeMirror-line > span::selection {
  background: #d7d4f0;
}

.CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}

.CodeMirror-line::-moz-selection {
  background: #d7d4f0;
}

.CodeMirror-line > span::-moz-selection {
  background: #d7d4f0;
}

.CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}

.cm-searching {
  background-color: #ff06;
}

.cm-force-border {
  padding-right: .1px;
}

@media print {
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

.cm-tab-wrap-hack:after {
  content: "";
}

span.CodeMirror-selectedtext {
  background: none;
}

.EasyMDEContainer {
  display: block;
}

.CodeMirror-rtl pre {
  direction: rtl;
}

.EasyMDEContainer.sided--no-fullscreen {
  flex-flow: wrap;
  display: flex;
}

.EasyMDEContainer .CodeMirror {
  box-sizing: border-box;
  font: inherit;
  z-index: 0;
  word-wrap: break-word;
  border: 1px solid #ced4da;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  height: auto;
  padding: 10px;
}

.EasyMDEContainer .CodeMirror-scroll {
  cursor: text;
}

.EasyMDEContainer .CodeMirror-fullscreen {
  z-index: 8;
  background: #fff;
  height: auto;
  inset: 50px 0 0;
  border-right: none !important;
  border-bottom-right-radius: 0 !important;
  position: fixed !important;
}

.EasyMDEContainer .CodeMirror-sided {
  width: 50% !important;
}

.EasyMDEContainer.sided--no-fullscreen .CodeMirror-sided {
  border-bottom-right-radius: 0;
  flex: auto;
  position: relative;
  border-right: none !important;
}

.EasyMDEContainer .CodeMirror-placeholder {
  opacity: .5;
}

.EasyMDEContainer .CodeMirror-focused .CodeMirror-selected {
  background: #d9d9d9;
}

.editor-toolbar {
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 9px 10px;
  position: relative;
}

.editor-toolbar.fullscreen {
  box-sizing: border-box;
  opacity: 1;
  z-index: 9;
  background: #fff;
  border: 0;
  width: 100%;
  height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  top: 0;
  left: 0;
}

.editor-toolbar.fullscreen:before {
  background: -moz-linear-gradient(left, #fff 0, #fff0 100%);
  background: -o-linear-gradient(left, #fff 0, #fff0 100%);
  background: -ms-linear-gradient(left, #fff 0, #fff0 100%);
  background: linear-gradient(to right, #fff 0, #fff0 100%);
  width: 20px;
  height: 50px;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
}

.editor-toolbar.fullscreen:after {
  background: -moz-linear-gradient(left, #fff0 0, #fff 100%);
  background: -o-linear-gradient(left, #fff0 0, #fff 100%);
  background: -ms-linear-gradient(left, #fff0 0, #fff 100%);
  background: linear-gradient(to right, #fff0 0, #fff 100%);
  width: 20px;
  height: 50px;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
}

.EasyMDEContainer.sided--no-fullscreen .editor-toolbar {
  width: 100%;
}

.editor-toolbar .easymde-dropdown, .editor-toolbar button {
  text-align: center;
  cursor: pointer;
  background: none;
  border: 1px solid #0000;
  border-radius: 3px;
  height: 30px;
  margin: 0;
  padding: 0;
  display: inline-block;
  text-decoration: none !important;
}

.editor-toolbar button {
  white-space: nowrap;
  min-width: 30px;
  padding: 0 6px;
  font-weight: 700;
}

.editor-toolbar button.active, .editor-toolbar button:hover {
  background: #fcfcfc;
  border-color: #95a5a6;
}

.editor-toolbar i.separator {
  color: #0000;
  text-indent: -10px;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #fff;
  width: 0;
  margin: 0 6px;
  display: inline-block;
}

.editor-toolbar button:after {
  vertical-align: text-bottom;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 65%;
  position: relative;
  top: 2px;
}

.editor-toolbar button.heading-1:after {
  content: "1";
}

.editor-toolbar button.heading-2:after {
  content: "2";
}

.editor-toolbar button.heading-3:after {
  content: "3";
}

.editor-toolbar button.heading-bigger:after {
  content: "▲";
}

.editor-toolbar button.heading-smaller:after {
  content: "▼";
}

.editor-toolbar.disabled-for-preview button:not(.no-disable) {
  opacity: .6;
  pointer-events: none;
}

@media only screen and (max-width: 700px) {
  .editor-toolbar i.no-mobile {
    display: none;
  }
}

.editor-statusbar {
  color: #959694;
  text-align: right;
  padding: 8px 10px;
  font-size: 12px;
}

.EasyMDEContainer.sided--no-fullscreen .editor-statusbar {
  width: 100%;
}

.editor-statusbar span {
  min-width: 4em;
  margin-left: 1em;
  display: inline-block;
}

.editor-statusbar .lines:before {
  content: "lines: ";
}

.editor-statusbar .words:before {
  content: "words: ";
}

.editor-statusbar .characters:before {
  content: "characters: ";
}

.editor-preview-full {
  z-index: 7;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}

.editor-preview-side {
  z-index: 9;
  box-sizing: border-box;
  word-wrap: break-word;
  border: 1px solid #ddd;
  width: 50%;
  display: none;
  position: fixed;
  top: 50px;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.editor-preview-active-side {
  display: block;
}

.EasyMDEContainer.sided--no-fullscreen .editor-preview-active-side {
  flex: auto;
  height: auto;
  position: static;
}

.editor-preview-active {
  display: block;
}

.editor-preview {
  background: #fafafa;
  padding: 10px;
}

.editor-preview > p {
  margin-top: 0;
}

.editor-preview pre {
  background: #eee;
  margin-bottom: 10px;
}

.editor-preview table td, .editor-preview table th {
  border: 1px solid #ddd;
  padding: 5px;
}

.cm-s-easymde .cm-tag {
  color: #63a35c;
}

.cm-s-easymde .cm-attribute {
  color: #795da3;
}

.cm-s-easymde .cm-string {
  color: #183691;
}

.cm-s-easymde .cm-header-1 {
  font-size: calc(1.375rem + 1.5vw);
}

.cm-s-easymde .cm-header-2 {
  font-size: calc(1.325rem + .9vw);
}

.cm-s-easymde .cm-header-3 {
  font-size: calc(1.3rem + .6vw);
}

.cm-s-easymde .cm-header-4 {
  font-size: calc(1.275rem + .3vw);
}

.cm-s-easymde .cm-header-5 {
  font-size: 1.25rem;
}

.cm-s-easymde .cm-header-6 {
  font-size: 1rem;
}

.cm-s-easymde .cm-header-1, .cm-s-easymde .cm-header-2, .cm-s-easymde .cm-header-3, .cm-s-easymde .cm-header-4, .cm-s-easymde .cm-header-5, .cm-s-easymde .cm-header-6 {
  margin-bottom: .5rem;
  line-height: 1.2;
}

.cm-s-easymde .cm-comment {
  background: #0000000d;
  border-radius: 2px;
}

.cm-s-easymde .cm-link {
  color: #7f8c8d;
}

.cm-s-easymde .cm-url {
  color: #aab2b3;
}

.cm-s-easymde .cm-quote {
  color: #7f8c8d;
  font-style: italic;
}

.editor-toolbar .easymde-dropdown {
  background: linear-gradient(to bottom right, #fff 0 84%, #333 50% 100%);
  border: 1px solid #fff;
  border-radius: 0;
  position: relative;
}

.editor-toolbar .easymde-dropdown:hover {
  background: linear-gradient(to bottom right, #fff 0 84%, #333 50% 100%);
}

.easymde-dropdown-content {
  visibility: hidden;
  z-index: 2;
  background-color: #f9f9f9;
  padding: 8px;
  display: block;
  position: absolute;
  top: 30px;
  box-shadow: 0 8px 16px #0003;
}

.easymde-dropdown:active .easymde-dropdown-content, .easymde-dropdown:focus .easymde-dropdown-content, .easymde-dropdown:focus-within .easymde-dropdown-content {
  visibility: visible;
}

.easymde-dropdown-content button {
  display: block;
}

span[data-img-src]:after {
  content: "";
  background-image: var(--bg-image);
  padding-top: var(--height);
  width: var(--width);
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 100%;
  height: 0;
  max-height: 100%;
  display: block;
}

.CodeMirror .cm-spell-error:not(.cm-url):not(.cm-comment):not(.cm-tag):not(.cm-word) {
  background: #ff000026;
}

/*# sourceMappingURL=index.88cfa4d1.css.map */
